import React from "react"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ProjectsContent from "../components/indonesian/projects/ProjectsContentId"
import PageHeader from "../components/indonesian/PageHeaderId"

const ProjectsId = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="Proyek"
        seoKeywords="Proyek JFEEI, Proyek JFE Engineering Indonesia, Proyek PT. JFE Engineering Indonesia, Layanan & Solusi JFEEI, Layanan & Solusi JFE Engineering Indonesia, Layanan & Solusi PT. JFE Engineering Indonesia, Perusahaan EPC, Industrial Plant, Pelumas, Petrokimia, Manufaktur, Perusahaan EPC, Energi, Minyak & Gas, Fasilitas Penyimpanan, Fasilitas Penerimaan, Perpipaan, Perusahaan EPC, Energi Terbarukan, Panas Bumi, Perusahaan EPC, Pengelolaan Lingkungan, Pengelolaan Limbah, WWTP, WTP, WHR"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <PageHeader
          pageTitle="Proyek"
          pageDescription={data.markdownRemark.frontmatter.header?.description}
        />
        <ProjectsContent />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default ProjectsId

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "projects-id" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
