import React from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Heading24, Heading32, Paragraph16 } from "../../../styles/PageStyles"

const ProjectsContentId = () => {
  const NextArrow = ({ onClick }) => {
    return (
      <IoMdArrowDropright
        className="directional-arrow left"
        onClick={onClick}
        size={100}
      />
    )
  }

  const PrevArrow = ({ onClick }) => {
    return (
      <IoMdArrowDropleft
        className="directional-arrow right"
        onClick={onClick}
        size={100}
      />
    )
  }

  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "projects-id" } }) {
        frontmatter {
          projects {
            title
            id
            tags {
              tag
            }
            description
            defaultImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            animationDelay
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              title
              hidden
              information {
                title
                description
              }
            }
          }
          servicesAndSolution {
            hidden
            title
            image {
              publicURL
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark

  return (
    <ProjectsContainer fluid>
      <Row className="ml-mr mb">
        {frontmatter?.projects?.map((project, index) => (
          <Col className="project-card-col" key={index} lg={6} md={12} xs={12}>
            <ProjectCard
              data-aos="fade-down"
              data-aos-delay={project?.animationDelay}
            >
              <Link to={`#${project?.id}`}>
                <GatsbyImage
                  image={getImage(project?.defaultImage)}
                  alt={project?.title}
                  className="project-img"
                />
                <div className="project-name">
                  <Heading24>{project?.title}</Heading24>
                </div>
              </Link>
            </ProjectCard>
          </Col>
        ))}
      </Row>
      {!frontmatter.servicesAndSolution?.hidden && (
        <Row>
          <ServicesAndSolutionContainer>
            <div>
              <Heading32 className="pt-50">
                {frontmatter.servicesAndSolution?.title}
              </Heading32>
              <div className="services-and-solution-img">
                <img
                  src={frontmatter.servicesAndSolution?.image?.publicURL}
                  alt={frontmatter.servicesAndSolution?.title}
                />
              </div>
            </div>
          </ServicesAndSolutionContainer>
        </Row>
      )}
      {frontmatter.projects?.map((project, index) => (
        <Row key={index} id={`${project?.id}`} className="project pt-20">
          <div>
            <Heading32>{project?.title}</Heading32>
          </div>
          <Heading24 className="project-tags">
            {project?.tags?.map((item, subIndex) => (
              <>
                {item?.tag} <span key={subIndex}>&nbsp;</span>
              </>
            ))}
          </Heading24>
          <Paragraph16>
            <ReactMarkdown>{project?.description}</ReactMarkdown>
          </Paragraph16>
          <StyledSlider {...carouselSettings}>
            {project?.images.map(
              (item, imgIndex) =>
                !item?.hidden && (
                  <div key={imgIndex} className="item-container">
                    <CarouselItem>
                      <GatsbyImage
                        image={getImage(item?.image)}
                        alt={item?.title}
                        className="carousel-img"
                        draggable={false}
                      />
                      <div className="carousel-item-title">
                        <Heading24 className="title">{item?.title}</Heading24>
                      </div>
                      <div className="carousel-item-hover">
                        <div className="heading">
                          <Heading24 className="title">{item?.title}</Heading24>
                        </div>
                        <div className="description">
                          {item?.information?.map((info, infoIndex) => (
                            <Heading24
                              key={infoIndex}
                            >{`${info?.title}: ${info?.description}`}</Heading24>
                          ))}
                        </div>
                      </div>
                    </CarouselItem>
                  </div>
                )
            )}
          </StyledSlider>
        </Row>
      ))}
    </ProjectsContainer>
  )
}

export default ProjectsContentId

const ProjectsContainer = styled(Container)`
  .pt-20 {
    padding-top: 50px;
  }
  padding-bottom: 25px;
  background: #f7f8fc;
  padding: 0 10% 50px 10%;

  .project-card-col {
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.02);
      transition: 0.3s ease-in-out;
    }

    @media only screen and (max-width: 500px) {
      padding: 0 12%;

      h1 {
        font-size: 14px;
      }
    }
  }

  .project {
    display: flex;
    justify-content: center;
    h1 {
      span {
        border-left: 4px solid #d1e869;

        &:last-child {
          border: none;
        }
      }
    }

    p {
      text-align: justify;
      margin: 25px 0;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ml-mr {
    padding: 0 15%;
  }

  .mb {
    margin-bottom: 50px;
  }

  .white-bg {
    background: #ffffff;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.07);
  }

  .horizontal-line {
    height: 4px;
    background: #e7eaf3;
  }

  .dashed-line {
    width: 900px;
    border-bottom: 2px dashed #0064ed;
  }

  hr {
    height: 3px;
    overflow: hidden;

    &:after {
      content: "......";
      letter-spacing: 4px;
    }
  }

  .aos-animate {
    pointer-events: all;
  }

  .slick-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .ml-mr {
      padding: 0 20%;
    }

    .slick-slider {
      padding: 0 75px;
    }
  }

  @media only screen and (max-width: 769px) {
    padding: 0 5% 50px 5%;
  }

  @media only screen and (max-width: 500px) {
    .ml-mr {
      padding: 0;
    }
  }
`

const ProjectCard = styled.div`
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 12px 0;
  pointer-events: none;

  .project-img {
    width: 100%;
    height: 16vw;
    border-radius: 10px 10px 0 0;
  }

  .project-name {
    border-radius: 0px 0px 10px 10px;
    background: linear-gradient(180deg, #149be1 23.97%, #0073af 192.47%);
    padding: 15px;

    h1 {
      text-align: center;
      color: #fff;
      margin: 0;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .project-img {
      height: 12vw;
    }

    .project-name {
      padding: 10px;
    }
  }

  @media only screen and (max-width: 992px) {
    .project-img {
      height: 36vw;
    }

    .project-name {
      padding: 5px;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 430px) {
    .project-name {
      padding: 5px;
    }
  }
`

const ServicesAndSolutionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #eef3f5;
  padding-bottom: 50px;

  h1 {
    text-align: center;
  }

  .services-and-solution-img {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    img {
      width: 45vw;
      height: auto;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .services-and-solution-img {
      img {
        width: 40vw;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .services-and-solution-img {
      img {
        width: 60vw;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .services-and-solution-img {
      display: flex;
      justify-content: center;
      img {
        width: 90vw;
        height: auto;
      }
    }
  }
`

const StyledSlider = styled(Slider)`
  padding: 0;
  margin-bottom: 25px;

  .directional-arrow {
    fill: #bcc1c3;
    transform: scaleX(1.5);
    cursor: pointer;
    min-width: 100px;
    min-height: 100px;
  }

  .item-container img {
    height: 500px;
  }

  .slick-list {
    width: 100%;
  }

  .slick-slide {
    margin-bottom: 35px;
  }

  .slick-dots {
    li button:before {
      display: none;
    }

    .slick-active {
      button {
        background: #0073af;
      }
    }

    button {
      width: 18px;
      height: 18px;
      background: #bcc1c3;
      border-radius: 50%;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .item-container img {
      height: 400px;
    }
  }

  @media only screen and (max-width: 1025px) {
    .item-container img {
      height: 50vw;
    }

    margin-left: -7.5px;
    .slick-slide {
      padding: 0 15px;
      overflow: hidden;
    }

    .slick-list {
      margin: 0 -15px;
    }
  }

  @media only screen and (max-width: 500px) {
    .item-container img {
      height: 70vw;
      object-fit: cover;
      object-position: center;
    }
  }
`

const CarouselItem = styled.div`
  width: 888px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  .carousel-img {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-weight: 400;
    color: #ffffff;
    padding: 0 50px;
    margin: 0 !important;
  }

  .title {
    font-weight: bold;
    padding: 15px 50px;
  }

  .carousel-item-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 115, 175, 0.9) 0%,
      rgba(20, 155, 225, 0.9) 0.01%,
      rgba(0, 115, 175, 0.9) 141.23%
    );
    opacity: 1;
    transform: translateY(0%);
    transition: 0.3s ease-in-out;
  }

  .carousel-item-hover {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transform: translateY(50%);
    transition: 0.3s ease-in-out;

    .heading {
      background: linear-gradient(
        180deg,
        rgba(209, 232, 105, 0.9) 0%,
        rgba(170, 197, 50, 0.9) 100%
      );
    }

    .description {
      padding: 15px 0;
      background: linear-gradient(
        180deg,
        rgba(0, 115, 175, 0.9) 0%,
        rgba(20, 155, 225, 0.9) 0.01%,
        rgba(0, 115, 175, 0.9) 100%
      );

      p {
        font-weight: 500;
      }
    }
  }

  &:hover {
    .carousel-item-title {
      opacity: 0;
      transform: translateY(100%);
      transition: 0.3s ease-in-out;
    }

    .carousel-item-hover {
      opacity: 1;
      transform: translateY(0%);
      transition: 0.3s ease-in-out;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    width: 680px;

    h1 {
      padding: 0 25px;
    }

    .title {
      padding: 15px 25px;
    }
  }

  @media only screen and (max-width: 1025px) {
    width: 100%;

    h1 {
      padding: 0 10px;
      line-height: 32px;
      white-space: normal;
    }

    .title {
      padding: 10px;
    }

    .description {
      padding: 10px 0 !important;
    }
  }

  @media only screen and (max-width: 500px) {
    h1 {
      font-size: 14px;
      line-height: 18px;
    }
  }
`
